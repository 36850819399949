import React from "react";
import styled from "styled-components";


const IconFooter = () => (
  <IconContainer>
    <a href="https://www.linkedin.com/in/stephen-bullis/">
      <Icon alt="Linked In Logo" src="https://icon.now.sh/linkedin/aaa" />
    </a>
    <a href="tel:+15207626258">
      <Icon src="https://icon.now.sh/phone/aaa" />
    </a>
    <a href="mailto:steve@stevebullis.com">
      <Icon src="https://icon.now.sh/email/aaa" />
    </a>
  </IconContainer>
);

export default IconFooter;

const IconContainer = styled.div`
  margin: 20px;
  text-align: center;
  
  @media screen and (min-height: 850px) {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  padding: 0.5em;
`;