import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";

import HomePageScreen from '../components/HomePageScreen';
import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";
import DateFormatter from "../components/DateContainer";



const Description = styled.div`
  font-size: 9pt;
  margin: 12px 0;
`;

const DateFields = styled.div`
  font-family: "Roboto";
  font-size: 10pt;
  text-transform: uppercase;
  @media screen and (max-width: 7.9in) {
    & {
      display: block;
      margin: 5px 0;
      color: var(--primary-color);
    }
  }
`;

const TitleLink = styled(Link)`
  @media screen and (max-width: 7.9in) {
    & {
      margin-bottom: 5px 0;
    }
  }
  flex-grow: 1;
  letter-spacing: 1px;
  font-family: "Roboto";
  font-size: 12pt;
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: var(--accent-color);
    text-decoration: underline;
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const PrintOnlyHeader = styled.div`
  display: none;

  @media print {
    & {
      font-size: 8pt;
      min-width: 1in;
      display: block;
    }
  }
`;

const PrintOnlyLink = styled.div`
  display: none;

  @media print {
    a {
      letter-spacing: 1px;
      font-family: "Roboto";
      font-size: 8pt;
      text-decoration: none;
      color: var(--accent-color);
    }
    a:visited {
      color: var(--accent-color);
    }

    a:hover {
      color: var(--accent-color);
      text-decoration: underline;
    }
    & {
      min-width: 1in;
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  padding-bottom: 5px;
  @media screen {
    & {
      border-bottom: 2px solid var(--primary-color);
      border-image: -webkit-gradient(
          linear,
          left bottom,
          right bottom,
          from(var(--primary-color)),
          to(var(--background-color)),
          color-stop(0.25, var(--background-color))
        )
        31 30 30 31 repeat repeat;
      border-image-repeat: stretch;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 7.9in) {
    & {
      margin: 30px 0;
      border-bottom: 2px solid var(--primary-color);
      border-image: -webkit-gradient(
          linear,
          left bottom,
          right bottom,
          from(var(--primary-color)),
          to(var(--background-color)),
          color-stop(0.5, var(--background-color))
        )
        31 30 30 31 repeat repeat;
      border-image-repeat: stretch;
    }
  }
`;

const WorkItem = ({
  title,
  position,
  startDate,
  endDate,
  description,
  slug,
  shortUrl
}) => {
  return (
    <Wrapper>
      <Flex>
        <TitleLink to={slug}>
          <span>{title}</span> - {position}
        </TitleLink>
        <PrintOnlyLink>
          <Link to={slug}>{shortUrl}</Link>
        </PrintOnlyLink>
      </Flex>
      <DateFields>
        <DateFormatter date={startDate} /> -{" "}
        <DateFormatter nullValue="Current" date={endDate} />
      </DateFields>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const ConferenceItem = ({ title, startDate, description, slug }) => {
  return (
    <>
      <div>
        <TitleLink to={slug}>
          <DateFields>
            <span>{title}</span> - <DateFormatter date={startDate} />
          </DateFields>
        </TitleLink>
      </div>

      <Description>{description}</Description>
    </>
  );
};
const FlexTitle = styled.div`
  display: flex;
  align-items: center;
`;
const Section = styled.section`
  margin-top: 10px;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  flex-grow: 1;
  font-family: Roboto;
  font-size: 12pt;
  text-transform: uppercase;
  margin: 0 0 6pt;
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-image: -webkit-gradient(
      linear,
      left bottom,
      right bottom,
      from(var(--primary-color)),
      to(var(--background-color)),
      color-stop(0.75, var(--background-color))
    )
    31 30 30 31 repeat repeat;
  border-image-repeat: stretch;
`;
const WorkHistory = ({ history }) => (
  <Section>
    <FlexTitle>
      <SectionTitle>Work Experience</SectionTitle>
      <PrintOnlyHeader>For addl. info visit</PrintOnlyHeader>
    </FlexTitle>
    <div>
      {history.map(workItem => (
        <WorkItem key={workItem.slug} {...workItem} />
      ))}
    </div>
  </Section>
);

const ConferenceHistory = ({ history }) => (
  <Section>
    <SectionTitle>Conferences</SectionTitle>
    <div>
      {history.map(workItem => (
        <ConferenceItem key={workItem.slug} {...workItem} />
      ))}
    </div>
  </Section>
);

const Index = ({ data: { page, work, conference } }) => {
  const workHistory = (work.edges || []).map(
    workItem => workItem.node.frontmatter
  );
  const conferenceHistory = (conference.edges || []).map(
    workItem => workItem.node.frontmatter
  );

  

  return (
     <HomePageScreen />
    // <Layout {...page.frontmatter}>
    //   <ContentMDX source={page.body} />
    //   <WorkHistory history={workHistory} />
    //   <ConferenceHistory history={conferenceHistory} />
    // </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query Index($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
        position
        startDate
        endDate
        shortUrl
        featuredImage
      }
    }
    work: allMdx(
      filter: { frontmatter: { template: { eq: "WorkMDX" } } }
      sort: {
        order: DESC
        fields: [
          frontmatter___sortOrder
          frontmatter___startDate
          frontmatter___endDate
        ]
      }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            title
            subtitle
            profile
            position
            startDate
            endDate
            shortUrl
            featuredImage
            description
          }
        }
      }
    }
    conference: allMdx(
      filter: { frontmatter: { template: { eq: "ConferenceMDX" } } }
      sort: { order: DESC, fields: [frontmatter___startDate] }
    ) {
      edges {
        node {
          body
          frontmatter {
            slug
            title
            position
            startDate
            attendanceType
            shortUrl
            featuredImage
            description
          }
        }
      }
    }
  }
`;
