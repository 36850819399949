import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ProfileImageContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledMobile = styled.div`
  @media screen and (min-width: 1120px) {
    display: none;
  }
`;

const StyledDesktop = styled.div`
  @media screen and (max-width: 1119px) {
    display: none;
  }

`;

const ProfileImage = ({ className }) => {
  const [hover, setHover] = useState(false);

  const defaultSrc = "/images/byday.png";
  let src = hover ? "images/portHoleGame.png" : defaultSrc;
  if (typeof window !== `undefined`  && typeof document.documentElement !== `undefined`){
    src = getComputedStyle(document.documentElement)
    .getPropertyValue('--profile-image')
  }

  return (
    <ProfileImageContainer className={className}>
        <StyledMobile>
            <img src={defaultSrc} alt="Profile Photo"/>
        </StyledMobile>
        <StyledDesktop
          // onMouseEnter={() => setHover(true)}
          // onMouseLeave={() => setHover(false)}
        >
          <Link to="/platformerGame">
            <img src={src} alt="Profile Photo"           alt="Profile Photo"/>
      </Link>
      </StyledDesktop>
    </ProfileImageContainer>
  );
};

export default ProfileImage;
